import React from 'react'
import styled from '@emotion/styled';
import { Box, Container, Grid } from '@mui/material';
import Regtxt from '../../Regtxt';
import ipsum1 from '../../../../assets/Images/ipsum1.png'
import ipsum2 from '../../../../assets/Images/ipsum2.png'
import ipsum3 from '../../../../assets/Images/ipsum3.png'
import ipsum4 from '../../../../assets/Images/ipsum4.png'
import ipsum5 from '../../../../assets/Images/ipsum5.png'
import Partners from '../../Partnershiptitle';
import { Img } from '../../../Styles/Maintext.styles';
const  Contbg = styled(Box)` 
display: flex; 
width: 100%;
background: #060707;
padding: 40px 0px;
 `  
   const BoxLay = styled(Box)`
   max-width: 100%; 
   min-height: auto; 
   display:flex; 
   flex-direction: row; 
   justify-content: start; 
   background-color: #161616;  
   padding: 30px  40px; 
   gap: 20px; 
   border-radius: 7px;
 ` 
const  Partnership = () => { 


  return (
    <Contbg >   
        <Box display='flex' flexWrap="wrap" justifyContent='center' gap="50px" width="100%">
          <Img data-aos="zoom-in" data-aos-duration="600" src={ipsum1} alt="pic"/>
          <Img data-aos="zoom-in" data-aos-duration="800" src={ipsum2} alt="pic"/>
          <Img data-aos="zoom-in" data-aos-duration="1000" src={ipsum3} alt="pic"/>
          <Img data-aos="zoom-in" data-aos-duration="1200" src={ipsum4} alt="pic"/>
          <Img data-aos="zoom-in" data-aos-duration="1400" src={ipsum5} alt="pic"/>
        </Box>
    </Contbg>
  )
}

export default  Partnership