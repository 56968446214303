import React, { useEffect } from 'react'
import Home from '../../Components/BrickBlock_Section/S1-Home';
import About from '../../Components/BrickBlock_Section/S2-About';

import Tokenomics from '../../Components/BrickBlock_Section/S4-Tokenomics';
import Blog from '../../Components/BrickBlock_Section/S5-Blog';
import Ecosystem from '../../Components/BrickBlock_Section/S3-Ecosystem';
import Feature from '../../Components/BrickBlock_Section/S6-Feature';
import Summary from '../../Components/BrickBlock_Section/S7-Summary';
import End from '../../Components/BrickBlock_Section/S8-End';
import Partnership from '../../Components/BrickBlock_Section/S9-Partnership';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import Aos from 'aos';
// ..
const Main = () => { 
useEffect(()=>{

  Aos.init({
    duration:800,
  });
})

  return (  
    <>     
    <Home /> 
    {/* <Partnership/> */}
    <About />   
    <Feature />
    <Ecosystem />
    <Tokenomics /> 
    {/* <Blog /> */}
    <Summary/>
    <End/>
    </>
  )
}

export default Main;