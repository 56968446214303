import React from 'react'
import styled  from '@emotion/styled'
import { Box, Container, Grid } from '@mui/material'
import Bgimg from "../../../../assets/Images/imgbg.png" 
import Bgimgmobile from "../../../../assets/Images/Marketplacemobile.png" 
import Paragraph from '../../Paragraph'
import Regtxt from '../../Regtxt'
import BlueButton from '../../BlueButton'
import LaunchIcon from '@mui/icons-material/Launch'; 


const MainHeader = styled(Container)`
  min-height: auto; 
  display:flex;  
  flex-direction: column;
  justify-content:start; 
  align-items:center; 
  gap:20px; 
  margin-top: 100px;
  border-radius: 10px;
`
const HeaderGrid = styled(Grid)`
  min-height: auto;
  background-color: #171719;
  background-image: url(${Bgimg});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* overflow: hidden; */
  border-radius: 10px;
  padding: 40px 40px 60px;
  width: 100%;
  @media(max-width: 600px){
  background-image: url(${Bgimgmobile});
  padding-bottom: 250px;
  background-position: bottom;
  background-size: 100% 70%;
  background-repeat: no-repeat;
  }
` 
const BtmBox = styled(Box)` 
background-color:#0066FF;
min-height:  ${(props) => props.noht ? "auto" : '300px' } ;
display: flex; 
flex-direction: column;
align-items: center;
width: 100%; 
gap:20px;
border-radius: 10px;
 ` 
const Ecosystem = () => { 

    
  return (
    <MainHeader maxWidth="xl" id="ecosystem">  
       <HeaderGrid container display="flex" justifyContent='start' alignItems="center">   
        <Grid item xs={12} md={5.9} lg={5.9} display="flex" flexDirection="column" padding="20px 0 100px"  data-aos="zoom-in" >    
          <Paragraph variant="h6" fontFamily="AvR"  textTransform="uppercase" color="#0066FF">Market Place</Paragraph>
          <Regtxt variant="h3" fontSize="clamp(2.375rem, 1.375vw + 2.1rem, 3.75rem)" maxWidth="550px" >  
            Be a Part of <br /> Something Bigger
          </Regtxt>
          <Paragraph variant="body1" maxWidth="550px"> 
            Brick Block goes beyond just investing in a real estate market. It's about shaping the future alongside a community of like-minded individuals. We believe blockchain can revolutionize real estate ownership. By joining Brick Block, you contribute to a movement that breaks down barriers to entry and fosters a more accessible, transparent, and rewarding investment experience for all. It's not just about your investment; it's about shaping the future of real estate together.  
          </Paragraph> 
          <BlueButton sx={{backgroundColor:"transparent !important",border:"1px solid #707070 !important",marginTop:"40px"}} href="https://brickblock.gitbook.io/brickblock-whitepaper" target="blank"  >Read Whitepaper</BlueButton>
        </Grid> 
       </HeaderGrid>  
       <BtmBox noht padding="60px 0px">
              <Regtxt  fs599="33px" my="0px" fontWeight="500" textAlign="center" fontSize="48px">Brick Block Ecosystem</Regtxt> 
              <Box display="flex" justifyContent="center" flexWrap="wrap" alignitems="center" gap="20px"> 
                <BlueButton data-aos="zoom-in" data-aos-duration="800" sx={{border:"1px solid white !important", borderRadius:"5px !important",display:"flex", gap:"10px"}}>Rental Rewards  <LaunchIcon sx={{color:"white","hover":'color:"#0066FF"'}} /></BlueButton>
                <BlueButton data-aos="zoom-in" data-aos-duration="1000" sx={{border:"1px solid white !important", borderRadius:"5px !important",display:"flex", gap:"10px"}}>Real Estate Marketplace <LaunchIcon sx={{color:"white","hover":'color:"#0066FF"'}} /></BlueButton> 
                <BlueButton data-aos="zoom-in" data-aos-duration="1200" sx={{border:"1px solid white !important", borderRadius:"5px !important",display:"flex", gap:"10px"}}>Mint RWA NFT  <LaunchIcon sx={{color:"white","hover":'color:"#0066FF"'}} /></BlueButton>
              </Box>
      </BtmBox>
    </MainHeader> 
  ) 
  }
export default Ecosystem;