import React from 'react'
import block from '../../../../assets/Images/block.png'; 
import styled from '@emotion/styled'; 
import Paragraph from '../../Paragraph';
import { Box, Container, Grid } from '@mui/material';
import Regtxt from '../../Regtxt'; 
import m1 from '../../../../assets/Images/m1.png'; 
import m2 from '../../../../assets/Images/m2.png'; 
import AboutCard from './Component/Card/Index';


const Blackbg = styled(Container)`
    background: radial-gradient(#0066FF 0%, transparent 50%);
    overflow: hidden;
    min-height:  ${(props) => props.noht ? "auto" : "100vh"} ;
  `

const ColorGrid = styled(Grid)`
  z-index: 0;
  position: relative;
    transition: all .5s;
    &>.MuiGrid-root{
          &>.MuiBox-root {
            &>.image{
              scale: 1;
              transition: all .5s;
            }
          }
         }
    ::after{
      content: "";
      display: block;
      border-radius: 7px;
      width: 100%;
      height: 100%;
      background: linear-gradient(to left ,#0066ff76,transparent 40% 60%,#0066ff76);
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      opacity: 0;
      transition:  .5s;
      }
      :hover{
         ::after{
            opacity: 1;
         }
         &>.MuiGrid-root{
          &>.MuiBox-root {
            &>.image{
              scale: 1.06;
            }
          }
         }
      }
`
const About = () => { 


  return (
        <Blackbg maxWidth="xl" id="about" > 
          <Grid container gap="20px">  
            <ColorGrid item container xs={12} marginTop="120px"  sx={{backgroundColor:"#060707"}} borderRadius="8px" padding="100px 20px"> 
              <Grid item xs={12} md={5.9} lg={5.9} > 
                <Box display='grid' justifyItems='center'> 
                  <img className='image' Width="90%" src={block} alt='brickblock' style={{ maxWidth:'100%' }}/>
                </Box>
              </Grid>  
              <Grid item xs={12} md={5.9} lg={5.9} sx={{paddingTop:{xs:"100px",md:"0"}}} display="flex" flexDirection="column"  data-aos="zoom-in">    
                <Paragraph variant="h6" fontFamily="AvR" textTransform="uppercase" color="#0066FF">brick block</Paragraph>
                <Regtxt  fontSize="clamp(2.375rem, 1.375vw + 2.1rem, 3.75rem)">  
                  Digitize The Value And Revenues Of any Real Estate Property.
                </Regtxt>
                <Paragraph variant="body1" fontFamily="AvR" > 
                  Brick Block empowers the digitization of real estate value and revenue streams. By leveraging blockchain technology, we transform traditional property ownership into tradable tokens (NFTs) or fungible investment tokens. This unlocks new possibilities: fractional ownership allows investment in high-value properties with lower capital, while revenue streams like rent or resale profits can be automatically distributed to token holders, creating a transparent and efficient system for both investors and property owners. 
                </Paragraph>
              </Grid> 
          </ColorGrid>  
          <Grid item xs={12} md={5.8} lg={5.9}>  
            <AboutCard imgw='80px' psrc={m1} cardheight="250px" def1="Property Ownership via NFTs" def2="High-value properties are linked to unique NFTs, which act as digital deeds. You can directly buy these NFTs to own a portion of a specific property and potentially profit from its rent or resale." /> 
          </Grid> 
          <Grid item xs={12}  md={5.8} lg={5.9}>  
            <AboutCard imgw='80px' psrc={m2}  def1="Investment Pool with Fungible Tokens" def2="Instead of directly owning property, you can buy the platform's crypto tokens. A portion of your purchase goes towards a pool used to buy new properties and you can partake in the profits generated by it if you lock/stake your tokens to earn a specified annual return via the generated profits." />
          </Grid>
          </Grid>    

      </Blackbg>
  )
}

export default About;