import { Button, } from '@mui/material';
import React from 'react';
import styled from '@emotion/styled';


const StyledButton = styled(Button)`
text-transform: none;
color: white;
font-family: "PoppinsM";
width: fit-content;
min-width: 150px;
min-height: 35px;
border-radius: 5px;
font-size:16px;
padding:6px 15px;
background: #0066FF; 
&.Mui-disabled {
    color: #707070 !important;
    border-color: #707070 !important;
    background:  #979797 !important;
    cursor: not-allowed !important;
    /* z-index: 2; */
  }
&:hover{
  background-color: #0066FF;
  color: white;
}
@media(max-width: 599px){
        /* font-size: 16px; */

    }
`


const BlueButton = (props) => {
  return ( 
    <StyledButton  {...props} > {props.children} </StyledButton>
  );
}
 
export default BlueButton;