import styled from "@emotion/styled";

const PageWrapper = styled.div`
    background-color: ${ (props) => props.theme.pageColor};
    min-height: 100vh;
`
const InputField = styled.input`
  border: ${(props) => (props.b ? props.b : "1px solid #fff")};
  border-radius: 5px;
  padding: 1px 15px;
  height: 35px;
  background-color: transparent;
  color: #fff;
  font-size: 16px;
  width:100%;
  font-family: "AvR";
  max-width: ${(props) => (props.w ? props.w : "calc(100% - 30px)")};
  :focus {
    outline: 1px solid var(--primary-color);
  }
  @media (max-width: 599px) {
    flex-direction: ${(props) => (props.fd ? props.fd : "row")};
    align-items: flex-start;
    width: ${(props) => props.w599};
  }
`;
export { PageWrapper ,InputField}