import React from 'react'; 
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import Mediumtxt from '../../../../Mediumtxt';
import Paragraph from '../../../../Paragraph';
import { Img } from '../../../../../Styles/Maintext.styles';



const AboutCard = (props) => {
      
  const BoxLay = styled(Box)`
    max-width: 100%; 
    min-height: ${props => props.h? props.h : 'auto'}; 
    display:flex; 
    flex-direction: column; 
    justify-content: start; 
    background-color:#060707;  
    padding: 30px  40px; 
    gap: 20px; 
    border-radius: 7px;
    position: relative;
    transition: all .5s;
    z-index: 0;
    ::after{
      content: "";
      display: block;
      border-radius: 7px;
      width: 100%;
      height: 100%;
      background: linear-gradient(to left ,#0066ff76,transparent 40% 60%,#0066ff76);
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      opacity: 0;
      transition:  .5s;
      }
      :hover{
         ::after{
            opacity: 1;
         }
      }
  ` 
  const p =  props.padding ?  props.padding : '0px';
  return ( 
     <BoxLay padding={props.cardpading} h="280px"  data-aos="fade-up"> 
         <Box display='flex' sx={{flexDirection:{xs:"column",sm:"row"}}} justifyContent='start' alignItems='start' gap="15px"> 
            <Img w={props.imgw? props.imgw:"70px"}  src={props.psrc} alt="pic" style={{padding:`${p}`}} />
            <Mediumtxt variant='h4' fontWeight="400" color="white" sx={{maxWidth:{xs:"100%",sm:"70%"}}}>   
               {props.def1}
            </Mediumtxt>   
         </Box>
         <Paragraph variant="body1" lineHeight="1.5" fontWeight='400' maxWidth='100%' fontFamily="AvR" paddingBottom="30px"> 
             {props.def2} 
        </Paragraph>
     </BoxLay>
  )
}
export default AboutCard;