import { Container } from "@mui/material";
import React from "react";
import DesktopMenu from "./component/DesktopMenu";
import MobileMenu from "./component/MobileMenu";
import BlueButton from "../BlueButton";
import Marquee from "react-fast-marquee";
import Paragraph from "../Paragraph";

const LockerMenu = (props) => {
  const menuList = [
    // You can pass 4 perameters for menu title, link, target and customClass
    {
      title: "About",
      link: "#about",
    },
    // {
    //   title: "Features",
    //   link: "#features",
    // },
    {
      title: "Ecosystem",
      link: "#ecosystem",
    },
    {
      title: "Token",
      link: "#tokenomics",
    },
    {
      title: "Whitepaper",
      link: "/whitepaper.pdf",
      target:"blank"
    },
  
    {
      title: <BlueButton>Buy $BRICK</BlueButton>,
      link: "https://app.uniswap.org/swap?outputCurrency=0x00199C511DC889B8155fa425fc0363Ed481e8f48&chain=ethereum",
      p:"0 20px 0 5px",
      target:"blank"
    },
  ];
  return (
    <Container sx={{position:"fixed",top:"0",left:"50%",transform:"translate(-50%, 0)",background:"#00000042",backdropFilter:"blur(10px)",zIndex:"999999999"}} maxWidth="xl" >
      <Marquee pauseOnHover={true} speed={40} style={{width:"100%",background:"#0066FF"}}>
        <Paragraph sx={{padding:"5px 10px 5px"}}>Our contract has migrated to a new one to fix vulnerabilities. If you held tokens on the old contract you will be airdropped tokens in the new one.</Paragraph> 
      </Marquee>
      <DesktopMenu menuList={menuList} />
      <MobileMenu menuList={menuList} />
    </Container>
  );
};

export default LockerMenu;
